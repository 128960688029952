<template>
    <div class="wlm-payment">
        <div id="paymentSectionScrollMarker"></div>
        <BasePayment></BasePayment>
        <WelcomeStartCounter v-if="isUserFromWebinar" payment="true"></WelcomeStartCounter>
        <div class="landing-title-group">
            <div class="landing-title-group__small">
                Сам себе продюсер
            </div>
            <div class="landing-title-group__mid">Выберите удобный тариф</div>
        </div>
        <div class="wlm-payment__packages">
            <div class="wlm-payment__item">
                <div class="wlm-payment__title">Стандарт</div>

                <div class="wlm-payment__price">
                    {{numberWithSpaces(priceBase)}} <span class="curr-icon">₽</span>
                </div>

                <ul class="wlm-payment__list">
                    <li>
                        включает все секреты
                        по продвижению бизнеса в Инстаграм,
                        которым я научился за последние 5 лет
                    </li>
                    <li>
                        доступ к информации появится
                        автоматически сразу после
                        оплаты и сохранится на 3 месяца
                    </li>
                    <li>
                        на протяжении оплаченного периода,
                        вам будут доступны все обновления,
                        которые я буду вносить в проект
                    </li>
                </ul>
                <button class="es-button blue"
                        @click="startPayment(priceBaseSale, priceBase, 'standart')"
                >
                    Получить доступ
                </button>
            </div>

            <div class="wlm-payment__item blue">
                <div class="wlm-payment__title">Расширенный</div>
                <div class="wlm-payment__price">
                    {{numberWithSpaces(pricePremium)}} <span class="curr-icon">₽</span>
                </div>
                <ul class="wlm-payment__list">
                    <li>
                        включает тариф “Стандарт”
                    </li>
                    <li>
                        доступ к чату телеграм на 3 месяца,
                        где можно задавать вопросы и обмениваться опытом.
                    </li>
                </ul>
                <button class="es-button white"
                        @click="startPayment(pricePremiumSale, pricePremium, 'premium')"
                >
                     Получить доступ
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import BasePayment from '@/components/payment/BasePayment.vue';
import WelcomeStartCounter from '@/components/secondary-pages/welcome-landing/WelcomeStartCounter.vue';

export default {
  name: 'WelcomeLandingPayment',
  components: {
    BasePayment,
    WelcomeStartCounter,
  },
  data() {
    return {
      complete: false,
      isUserFromWebinar: this.$store.state.user.landingUserType === 'baseFromWebinar',
      isUserAuthed: this.$store.state.user.landingUserType === 'baseAuthed',
      priceBase: 3000,
      priceBaseSale: 3000,
      pricePremium: 6000,
      pricePremiumSale: 6000,
    };
  },
  computed: {
    isLandingSaleTimeOver() {
      return this.$store.state.user.isLandingSaleTimeOver;
    },
    meditationsInfo() {
      return this.$store.getters.getMeditationsInfoForCourse;
    },
  },
  methods: {
    startPayment(priceToPay, priceOld, type) {
      this.$store.commit('markCourseMeditationsForPay');
      this.$store.commit('setLandingPaymentPriceToPay', priceToPay);
      this.$store.commit('setLandingPaymentType', type);
      this.$store.commit('setPriceToPay', priceToPay);
      this.$store.commit('setLandingPaymentFullPrice', priceOld);
      this.$store.commit('setLandingPagePayment', true);
      this.$store.commit('showPayment');
    },
    numberWithSpaces(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    },
  },
};
</script>

<style scoped lang="scss">
 @import "../../../assets/scss/common/variables";
 @import "../../../assets/scss/common/landing";
 @import "../../../assets/scss/common/forms";

.wlm-payment {
    padding: 30px 24px 50px 24px;
    position: relative;
    background: $white;

    @media only screen and (max-width: 767px) {
        padding: 10px 24px 20px 24px;
    }

    #paymentSectionScrollMarker {
        position: absolute;
        left: 0;
        top: 0;
    }

    &__start-block {
        text-align: center;
        background: $main-blue;
        padding: 20px 15px 15px 15px;
        border-radius: 10px;
        color: $white;
    }

    &__buy-more {
        font-size: 17px;
        line-height: 22px;
        max-width: 600px;
        margin: auto;

        @media only screen and (max-width: 767px) {
            font-size: 19px;
            line-height: 24px;
        }

        strong {
            font-weight: 600;
            color: #eee61e;
        }
    }

    &__packages {
        display: flex;

        @media only screen and (max-width: 767px) {
            flex-direction: column;
        }
    }

    &__item {
        border: 2px solid #DCDFE7;
        box-sizing: border-box;
        border-radius: 10px;
        padding: 30px 30px 20px 30px;
        margin-right: 20px;
        width: 50%;
        padding-bottom: 95px;
        position: relative;

        @media only screen and (max-width: 767px) {
            width: auto;
            margin: 0 0 10px 0;
        }

        &:last-child {
            margin-right: 0;
        }

        &.blue {
            background: $main-blue;
            color: $white;

            .wlm-payment__title,
            .wlm-payment__price {
                color: $white;

                &.dark-blue {
                    color: #1443A4;
                }

                &.yellow {
                    color: #EEE61E;
                }
            }

            .wlm-payment__list li:before {
                background: url("/images/landing/check-icon-white.svg");
            }
        }

        button {
            font-size: 16px;
            font-weight: 600;
            font-family: $titleFont;
            position: absolute;
            bottom: 20px;
            height: 50px;
            width: calc(100% - 60px);

            @media only screen and (max-width: 767px) {
                font-size: 12px;
            }
        }
    }

    &__title {
        font-size: 22px;
        font-weight: 600;
        color: $main-blue;
        text-align: center;
        margin-bottom: 30px;

        @media only screen and (max-width: 767px) {
            font-size: 18px;
            margin-bottom: 15px;
        }
    }

    &__price-descr {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 20px;
        margin-top: -25px;
        text-align: center;
        display: block;

        @media only screen and (max-width: 767px) {
            font-size: 14px;
        }

        &.black {
            color: $black;
        }

        &.blue {
            color: $main-blue;
        }

        &.dark-blue {
            color: #1443A4;
        }

        &.white {
            color: $white;
        }

        &.yellow {
            color: #EEE61E;
        }
    }

    &__price {
        font-size: 38px;
        font-weight: 600;
        text-align: center;
        color: $main-blue;
        margin-bottom: 30px;

        @media only screen and (max-width: 767px) {
            margin-bottom: 26px;
            font-size: 33px;
        }

        .curr-icon {
            font-size: 33px;
        }

        &.black {
            color: $black;

            &:after {
                content: '';
                display: inline-block;
            }
        }

        &.blue {
            color: $main-blue;
        }

        &.dark-blue {
            color: #1443A4;
        }

        &.yellow {
            color: #EEE61E;
        }

        &.strikethrough {
            text-decoration: line-through;
            font-size: 28px;

            .curr-icon {
                font-size: 26px;
            }

            &.black {
                color: $black;
            }
        }
    }

    &__list {
        margin: 30px 0;

        @media only screen and (max-width: 767px) {
            margin-bottom: 1px;
        }

        li {
            list-style: none;
            padding-left: 30px;
            position: relative;
            font-size: 18px;
            line-height: 24px;
            font-weight: 500;
            margin-bottom: 20px;

            @media only screen and (max-width: 767px) {
               padding-left: 20px;
                font-size: 14px;
                line-height: 21px;
                margin-bottom: 5px;
            }

            .light {
                font-weight: 400;
                line-height: 30px;

                @media only screen and (max-width: 767px) {
                    line-height: 26px;
                }
            }

            &:before {
                position: absolute;
                width: 15px;
                height: 11px;
                background: url("/images/landing/check-icon-blue.svg");
                content: '';
                left: 0;
                top: 8px;

                @media only screen and (max-width: 767px) {
                   left: -4px;
                }
            }
        }
    }
}
</style>
