<template>
  <div class="landing-page">
    <BaseCabinet></BaseCabinet>
  </div>
</template>

<script>
// @ is an alias to /src
import BaseCabinet from '@/components/cabinet/BaseCabinet.vue';

export default {
  name: 'Cabinet',
  components: {
    BaseCabinet,
  },
};
</script>
