<template>
    <div class="base-webinars">
      <div class="base-webinars__title">Получить ссылку на вебинар</div>
      <WebinarJoinUrl></WebinarJoinUrl>
    </div>
</template>

<script>
import WebinarJoinUrl from './WebinarJoinUrl.vue';

export default {
  name: 'PromoStats',
  components: {
    WebinarJoinUrl,
  },
};
</script>

<style scoped lang="scss">
 @import "../../assets/scss/common/variables";
 @import "../../assets/scss/common/forms";

.base-webinars {
  margin: 40px 0 0;
  padding: 0 20px;

  &__title {
    margin-bottom: 15px;
    font-weight: 600;
  }
}

  .join-url {
    &__result-title {
      font-weight: 600;
      margin: 15px 0 15px 0;
    }

    &__show-links {
      margin: 30px 0 80px 0;
      font-size: 14px;
      line-height: 21px;
    }
  }
</style>
