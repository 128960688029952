<template>
  <div class="landing-page">
    <BaseWelcomeLanding></BaseWelcomeLanding>
  </div>
</template>

<script>
// @ is an alias to /src
import BaseWelcomeLanding from '@/components/secondary-pages/BaseWelcomeLanding.vue';

export default {
  name: 'Home',
  components: {
    BaseWelcomeLanding,
  },
};
</script>
