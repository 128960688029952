<template>
  <div class="admin-auth">
      <div class="admin-auth__form">
          <input type="email" placeholder="Логин" v-model="login"/>
          <input type="password" placeholder="Пароль" v-model="pass"/>
          <div class="admin-auth__form-actions">
              <button type="submit" @click="enter">Войти</button>
          </div>
      </div>
  </div>
</template>

<script>
export default {
  name: 'AdminAuth',
  data() {
    return {
      login: '',
      pass: '',
    };
  },
  methods: {
    enter() {
      const adminData = {
        name: this.login,
        token: this.pass,
      };

      this.$store.commit('setAdminToken', this.pass);
      localStorage.setItem('meditationAdmin', JSON.stringify(adminData));
    },
  },
};
</script>

<style lang="scss" scoped>
.admin-auth {
  padding: 50px;

  &__form {
    margin-top: 35px;

    input {
      width: 100%;
      box-sizing: border-box;
      height: 42px;
      padding: 10px 12px;
      border-radius: 4px;
      border: solid rgba(0,0,0,0.1) 1px;
      font-size: 14px;
      margin-bottom: 10px;
    }
  }

  button {
    width: 100%;
    margin: 10px 0 0 0;
    background: #6fb586;
    color: #fff;
    font-weight: 600;
    border: none;
    border-radius: 3px;
    text-align: center;
    cursor: pointer;
    height: 40px;
    font-size: 14px;
    padding: 0;

    &.gray {
      background: #a3a4a3;
      cursor: default;
    }
  }
}
</style>
