<template>
    <div class="welcome-page">
        <Header isNotFixed="true"></Header>
        <WelcomeLandingHeader></WelcomeLandingHeader>
        <CabinetPurchaseInfo></CabinetPurchaseInfo>
        <SinglePayment></SinglePayment>
        <Footer></Footer>
    </div>
</template>

<script>
import Header from '@/components/header/BaseHeader.vue';
import Footer from '@/components/general/Footer.vue';
import WelcomeLandingHeader from '@/components/secondary-pages/welcome-landing/WelcomeLandingHeader.vue';
import SinglePayment from '@/components/secondary-pages/welcome-landing/SinglePayment.vue';
import CabinetPurchaseInfo from '@/components/cabinet/CabinetPurchaseInfo.vue';

export default {
  name: 'BaseCabinet',
  components: {
    Header,
    WelcomeLandingHeader,
    SinglePayment,
    Footer,
    CabinetPurchaseInfo,
  },
  data() {
    return {
    };
  },
  computed: {
    getUserMail() {
      return this.$store.getters.getUserMail;
    },
  },
  mounted() {
    this.$store.commit('hideBurger');

    if (this.getUserMail === '') {
      this.$router.push({ name: 'Auth' });
    }
  },
  beforeDestroy() {
    document.documentElement.style.setProperty('--main-bg-color', '#ffffff');
  },
  beforeMount() {
    document.documentElement.style.setProperty('--main-bg-color', '#fff4f1');
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../../assets/scss/common/variables";

.welcome-page {
    .base-header {
        background: $white;
    }
}
</style>
