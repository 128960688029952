<template>
    <div class="wlm-works">
        <div class="landing-title-group">
            <div id="howItWorksSectionScrollMarker"
                 class="landing-title-group__hidden-marker"
            ></div>
            <div class="landing-title-group__small">
                ПОТОК ИСЦЕЛЕНИЯ
            </div>
            <div class="landing-title-group__mid">Как записаться?</div>
        </div>
        <div class="wlm-works__descr">
            Поток будет проходить в закрытой комнате Zoom,
            ссылку на эфир вы получите сразу после оплаты.
            Поток исцеления пройдет в прямом эфире,
            запись эфира будет сохранена всего
            на 3 дня (только для участников и зрителей),
            затем запись удалится навсегда.<br><br>

            Количество зрителей ограничено!
            Стоимость для зрителей будет повышаться
            каждый день до начала эфира.
            Присоединяйтесь к потоку сейчас!
        </div>
    </div>
</template>

<script>
export default {
  name: 'LandingHowToParticipate',
  data() {
    return {
    };
  },
  computed: {
  },
  methods: {
  },
};
</script>

<style scoped lang="scss">
 @import "../../../assets/scss/common/variables";
 @import "../../../assets/scss/common/landing";

.wlm-works {
    padding: 10px 24px 40px 24px;
    background: $light-blue;

    @media only screen and (max-width: 767px) {
        padding: 10px 24px 40px 24px;
    }

    &__descr {
        font-size: 16px;
        line-height: 24px;
        margin-top: 40px;

        @media only screen and (max-width: 767px) {
            font-size: 14px;
            margin-top: 30px;
            line-height: 21px;
        }
    }
}
</style>
