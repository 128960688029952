<template>
    <div class="webinar-success">
        <Header isNotFixed="true" isNoBurger="true"></Header>
        <WelcomeLandingWebinarSuccsess></WelcomeLandingWebinarSuccsess>
        <footer>
            © Елена Сягло. 2021
        </footer>
    </div>
</template>

<script>
import Header from '@/components/header/BaseHeader.vue';
import WelcomeLandingWebinarSuccsess from '@/components/secondary-pages/welcome-landing/WelcomeLandingWebinarSuccsess.vue';

export default {
  name: 'BaseWebinarSuccess',
  components: {
    Header,
    WelcomeLandingWebinarSuccsess,
  },
  data() {
    return {
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../../assets/scss/common/variables";

.webinar-success {
    padding: 40px 0 0 0;

    .base-header {
        background: $white;
    }

    footer {
        height: 74px;
        line-height: 74px;
        text-align: center;
        background: #1d1d1d;
        color: #9B9B9B;
        font-size: 12px;
    }
}
</style>
