<template>
  <div class="landing-page">
    <BaseFinalPrice></BaseFinalPrice>
  </div>
</template>

<script>
// @ is an alias to /src
import BaseFinalPrice from '@/components/secondary-pages/BaseFinalPrice.vue';

export default {
  name: 'Supermodule',
  components: {
    BaseFinalPrice,
  },
};
</script>
