import { render, staticRenderFns } from "./LandingWebinarDescr.vue?vue&type=template&id=4daf4294&scoped=true&"
import script from "./LandingWebinarDescr.vue?vue&type=script&lang=js&"
export * from "./LandingWebinarDescr.vue?vue&type=script&lang=js&"
import style0 from "./LandingWebinarDescr.vue?vue&type=style&index=0&id=4daf4294&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4daf4294",
  null
  
)

export default component.exports