import { render, staticRenderFns } from "./WelcomeLandingAbout.vue?vue&type=template&id=2a08006e&scoped=true&"
import script from "./WelcomeLandingAbout.vue?vue&type=script&lang=js&"
export * from "./WelcomeLandingAbout.vue?vue&type=script&lang=js&"
import style0 from "./WelcomeLandingAbout.vue?vue&type=style&index=0&id=2a08006e&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a08006e",
  null
  
)

export default component.exports