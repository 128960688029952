<template>
    <div class="wlm-payment">
        <BasePayment></BasePayment>
        <div class="wlm-payment__start-block">
            <div class="wlm-payment__buy-more">
                <p>
                    По многочисленным просьбам на 3 дня
                    открываем возможность купить модули отдельно
                    по супер низкой цене.
                    <strong>Таких скидок еще не было!</strong>
                </p>
            </div>
        </div>
        <div class="landing-title-group">
            <div class="landing-title-group__small">
                МЕДИТАЦИЯ В НОВУЮ ЖИЗНЬ
            </div>
            <div class="landing-title-group__mid">Выберите модуль</div>
        </div>
        <div class="wlm-payment__packages">
            <ModulePaymentItem
                    v-for="(groupData, index) in meditationsInfo.groupsWithNoPayedItems"
                    :data="groupData"
                    :key="index"
            ></ModulePaymentItem>
        </div>
    </div>
</template>

<script>
import BasePayment from '@/components/payment/BasePayment.vue';
import ModulePaymentItem from '@/components/secondary-pages/welcome-landing/ModulePaymentItem.vue';

export default {
  name: 'ModulePayment',
  components: {
    BasePayment,
    ModulePaymentItem,
  },
  data() {
    return {
      complete: false,
      isUserFromWebinar: this.$store.state.user.landingUserType === 'baseFromWebinar',
      isUserAuthed: this.$store.state.user.landingUserType === 'baseAuthed',
      priceBase: 17700,
      priceBaseSale: 17700,
      pricePremium: 32700,
      pricePremiumSale: 32700,
    };
  },
  computed: {
    meditationsInfo() {
      return this.$store.getters.getMeditationsInfoForCourse;
    },
  },
  methods: {
    startPayment(priceToPay, priceOld, type) {
      this.$store.commit('markCourseMeditationsForPay');
      this.$store.commit('setLandingPaymentPriceToPay', priceToPay);
      this.$store.commit('setLandingPaymentType', type);
      this.$store.commit('setPriceToPay', priceToPay);
      this.$store.commit('setLandingPaymentFullPrice', priceOld);
      this.$store.commit('setLandingPagePayment', true);
      this.$store.commit('showPayment');
    },
  },
  mounted() {
  },
  watch: {
  },
};
</script>

<style scoped lang="scss">
 @import "../../../assets/scss/common/variables";
 @import "../../../assets/scss/common/landing";
 @import "../../../assets/scss/common/forms";

.wlm-payment {
    padding: 30px 24px 50px 24px;
    position: relative;
    background: $white;

    @media only screen and (max-width: 767px) {
        padding: 10px 24px 20px 24px;
    }

    #paymentSectionScrollMarker {
        position: absolute;
        left: 0;
        top: 0;
    }

    &__start-block {
        text-align: center;
        background: $main-blue;
        padding: 20px 15px 15px 15px;
        border-radius: 10px;
        color: $white;
    }

    &__buy-more {
        font-size: 17px;
        line-height: 22px;
        max-width: 600px;
        margin: auto;

        @media only screen and (max-width: 767px) {
            font-size: 19px;
            line-height: 24px;
        }

        strong {
            font-weight: 600;
            color: #eee61e;
        }
    }

    &__packages {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        @media only screen and (max-width: 767px) {
            flex-direction: column;
        }
    }
}
</style>
