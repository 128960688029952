<template>
    <div class="welcome-about" :class="{'largeTopPadding': isCourseInfoPage}">
        <div class="welcome-about__header" v-if="!isCourseInfoPage">
            <div class="welcome-about__descr">
                <div class="welcome-about__descr-title">
                    Вы уже <span class="blue">проводите</span> консультации с клиентами
                    и у вас это получается? Вам
                    <span class="blue">нравится</span> то, чем вы занимаетесь?
                    Вам кажется, что вы нашли своё любимое дело?
                    <span class="welcome-about__dotes">.....</span>
                </div>
                <div class="welcome-about__descr-text">
                    Скоро вы узнаете, как я увеличил количество
                    подписчиков в Инстаграм со 160 человек до 170000 человек,
                    увеличил оборот компании с 20 тысяч российских
                    рублей до 2 миллионов в месяц.
                    Читайте историю моего успеха!
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'WelcomeLandingAbout',
  data() {
    return {
      glide: '',

    };
  },
  computed: {
    isCabinet() {
      return this.$route.name === 'Cabinet' || this.$route.name === 'CabinetFull';
    },
    isCourseInfoPage() {
      return this.$route.name === 'CourseInfo';
    },
  },
  methods: {
  },
};
</script>

<style scoped lang="scss">
 @import "../../../assets/scss/common/variables";
 @import "../../../assets/scss/common/landing";
 @import "../../../assets/scss/common/glide";

 .isDesktop {
     @media only screen and (max-width: 767px) {
         display: none;
     }
 }

 .isMobile {
     display: none;

     @media only screen and (max-width: 767px) {
         display: block;
     }
 }

 .welcome-about {
    padding: 50px 24px 10px 24px;
     background: $white;

     &.largeTopPadding {
         padding-top: 80px;
     }

     &__body {
         position: relative;
     }

     &__dotes {
         letter-spacing: 3px;
         font-size: 30px;
         margin-bottom: 20px;
         display: block;
     }

    @media only screen and (max-width: 767px) {
        padding: 30px 24px 20px 24px;
    }

    &__our-audience {
        display: flex;
        justify-content: space-between;
        margin: 40px 0 20px 0;
    }

     .glide-slider {
         position: relative;

         @media only screen and (max-width: 550px) {
             max-width: 340px;
             margin: auto;
         }

         &:after {
             @media only screen and (max-width: 550px) {
                 width: 40px;
                 height: 100%;
                 position: absolute;
                 right: 0;
                 top: 0;
                 content: '';
                 background:
                         linear-gradient(268.78deg, #FFFFFF 0.94%, rgba(255, 255, 255, 0) 138.27%);
             }
         }

         @media only screen and (max-width: 767px) {
            margin-top: -40px!important;
         }
     }

     .glide__arrows {
         display: flex;
         justify-content: center;
         align-items: center;
         margin-top: 10px;

         @media only screen and (min-width: 550px) {
             display: none;
         }

         .glide__arrow {
             background: url('/images/right-arrow.svg') no-repeat 50% 50% $main-blue;
             width: 24px;
             height: 24px;
             border-radius: 50%;
             margin: 0 10px;
             border: 0;
             position: relative;
             top: 1px;
             flex-shrink: 0;
             cursor: pointer;
             display: none;

             &--right {
                 transform: rotate(180deg);
             }
         }
     }

     &__dots {
         text-align: center;
         margin: 0 20px;

         .glide__bullet {
             width: 10px;
             height: 10px;
             border-radius: 50%;
             margin: 0 2px;
             background: rgba($main-blue, 0.1);
             border: 5px solid $white;
             padding: 0;
             box-sizing: unset;
             cursor: pointer;
         }

         .glide__bullet--active {
             background: $main-blue;
         }
     }

    &__our-audience-item {
        max-width: 280px;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        padding-top: 20px;
        margin: 0 8px;

        @media only screen and (max-width: 550px) {
            width: 170px!important;
        }

        &.baby {
            background: url(/images/landing/baby-icon.svg) no-repeat 50px 0;
        }

        &.education {
            background: url(/images/landing/had-icon.svg) no-repeat 35px 5px;
        }

        &.insta {
            background: url(/images/landing/insta-icon.svg) no-repeat 45px 4px;
        }
    }

    &__our-audience-small-text {
        color: $main-blue;
        font-size: 18px;
        margin-bottom: 5px;

        @media only screen and (max-width: 767px) {
            font-size: 14px;
        }
    }

    &__our-audience-count {
        color: $main-blue;
        font-size: 34px;
        font-weight: 600;
        margin-bottom: 8px;

        @media only screen and (max-width: 767px) {
            font-size: 25px;
        }
    }

    &__our-audience-text {
        font-family: $textFont;
        line-height: 24px;
        white-space: normal;

        @media only screen and (max-width: 767px) {
            font-size: 14px;
            line-height: 21px;
        }
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;

        @media only screen and (max-width: 700px) {
            flex-direction: column;
        }
    }

    &__main-image {
        width: 200px;
        height: 290px;
        background: url("/images/landing/welcome-about.png") 50% 50%;
        background-size: cover;
        border-radius: 120px;
        flex-shrink: 0;
        margin-left: 90px;
        margin-right: 20px;
        position: relative;

        @media only screen and (max-width: 700px) {
            margin-left: 0;
            margin-right: 0;
            margin-top: 20px;
        }

        &:after,
        &:before {
            position: absolute;
            left: -20px;
            top: 50%;
            width: 7px;
            height: 7px;
            background: #D0D6E7;
            content: '';
            border-radius: 50%;
            margin-top: -3px;
        }

        &:before {
            left: auto;
            right: -20px;
        }
    }

    &__descr-title {
        font-weight: 500;
        font-size: 22px;
        line-height: 32px;
        margin-bottom: 20px;

        @media only screen and (max-width: 700px) {
            font-size: 18px;
            line-height: 25px;
            margin-bottom: 2px;
        }

        .blue {
            color: $main-blue;
        }
    }

    &__descr-text {
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;

        @media only screen and (max-width: 700px) {
            font-size: 14px;
            line-height: 21px;
        }
    }

     &__cabinet-link {
         margin: 20px 0 0 0;

         @media only screen and (max-width: 700px) {
             margin: 40px 0 0 0;
         }

         a {
             color: $main-blue;
         }
     }

}
</style>
