<template>
    <div class="wlm-advice">
        <img src="/images/landing/quotes.svg"/> <br>
        Все зрители нашего эфира
        окунутся в поток исцеляющей
        энергии, волшебных
        проработок и практик. Каждый
        зритель во время эфира
        трансформирует себя и
        испытает невероятное чувство
        освободжения.
    </div>
</template>

<script>

export default {
  name: 'LandingAdvice',
  data() {
    return {
      complete: false,
    };
  },
  computed: {
  },
  methods: {
  },
};
</script>

<style scoped lang="scss">
 @import "../../../assets/scss/common/variables";
 @import "../../../assets/scss/common/landing";

.wlm-advice {
    padding: 105px 24px;
    background: $main-blue;
    color: $white;
    text-align: center;
    font-size: 22px;
    line-height: 30px;

    @media only screen and (max-width: 767px) {
        font-size: 18px;
        padding: 50px 24px 70px 24px;
        line-height: 23px;
    }

    br {
        @media only screen and (max-width: 767px) {
           display: none;
        }
    }

    img {
        display: inline-block;
        margin-bottom: 15px;

        @media only screen and (max-width: 767px) {
            height: 20px;
            display: block;
            margin: 0 auto 27px auto;
        }
    }
}
</style>
