<template>
    <form class="purchase-list" @submit.prevent="saveUserPayments()">
        <div class="purchase-list__list"
             v-for="(item, index) in list"
             :key="index"
        >
            <div class="purchase-list__group">
                <label class="purchase-list__group-title">
                    <input
                            type="checkbox"
                            class="js-groups"
                            :id="item.groupName"
                            :value="item.groupName"
                            :checked="isGroupPayed(item.groupName)"
                    >
                    {{item.title}}
                </label>
                <label class="purchase-list__group-item"
                       v-for="(medi, indexMedi) in item.list"
                       :key="indexMedi"
                >
                    <input type="checkbox"
                           class="js-group-item"
                           :id="medi.id"
                           :value="medi.id"
                           :checked="isItemPayed(medi.id)"
                    >
                    {{medi.name}}&nbsp;({{medi.id}})
                </label>
            </div>
        </div>
        <button type="submit" class="es-button">Сохранить покупки пользователя</button>
        <button type="button" class="es-button red" @click.stop="clearUserData()">
            Очистить все покупки пользователя
        </button>
    </form>
</template>

<script>
import axios from 'axios';

export default {
  name: 'PurchaseList',
  components: {},
  data() {
    return {
      complete: false,
      options: {},
      result: false,
    };
  },
  computed: {
    list() {
      return this.$store.state.meditations.meditationsList;
    },
    usersPayedItems() {
      return this.$store.state.admin.usersPayedItems.split(',');
    },
    usersPayedGroups() {
      return this.$store.state.admin.usersPayedGroups.split(',');
    },
    mail() {
      return this.$store.state.admin.currentWorkingMail;
    },
    adminToken() {
      return this.$store.state.user.adminToken;
    },
  },
  methods: {
    clearUserData() {
      /* eslint no-alert: "off" */
      const confirmMessage = window.confirm('Вы уверены что хотите очистить данные пользователя?');

      if (!confirmMessage) {
        return false;
      }

      const currentUrl = this.$store.getters.getLocalUrl;

      axios
        .post(`${currentUrl}/api/adminClearUserData.php`, {
          adminToken: this.adminToken,
          mail: this.mail,
        })
        .then((response) => {
          if (response.data.success) {
            this.$parent.userData = response.data.result;
            this.$parent.result = true;
            this.$parent.hasUserData = false;
          }
        });

      return true;
    },
    saveUserPayments() {
      /* eslint no-alert: "off" */
      const confirmMessage = window.confirm(`Сохранить изменения списка покупок для ${this.mail}?`);

      if (!confirmMessage) {
        return false;
      }

      const inputsList = document.querySelectorAll('.js-group-item:checked');
      const inputsGroupsList = document.querySelectorAll('.js-groups:checked');
      let finalPurchasesList = [];
      let finalPackgesList = [];

      inputsList.forEach((input) => {
        finalPurchasesList.push(input.value);
      });

      inputsGroupsList.forEach((input) => {
        finalPackgesList.push(input.value);
      });

      finalPurchasesList = finalPurchasesList.join(',');
      finalPackgesList = finalPackgesList.join(',');

      const currentUrl = this.$store.getters.getLocalUrl;

      axios
        .post(`${currentUrl}/api/adminChangeUserData.php`, {
          adminToken: this.adminToken,
          mail: this.mail,
          finalPurchasesList,
          finalPackgesList,
        })
        .then((response) => {
          if (response.data.success) {
            this.$parent.userData = response.data.result;
            this.$parent.resultEdit = true;
            this.$parent.hasUserData = false;
          }
        });

      return true;
    },
    isGroupPayed(id) {
      let isPayed = false;
      this.usersPayedGroups.forEach((item) => {
        if (String(id) === String(item)) {
          isPayed = true;
        }
      });

      return isPayed;
    },
    isItemPayed(id) {
      let isPayed = false;
      this.usersPayedItems.forEach((item) => {
        if (String(id) === String(item)) {
          isPayed = true;
        }
      });

      return isPayed;
    },
  },
};
</script>

<style scoped lang="scss">
 @import "../../assets/scss/common/variables";
 @import "../../assets/scss/common/forms";

.purchase-list {
    margin: 40px 0 50px 0;
    line-height: 24px;
    display: block;

    &__group-title {
        font-weight: 800;
        margin:25px 0 15px 0;
        display: block;
    }

    &__group-item {
        display: block;
        padding-left: 25px;
    }

    input[type=checkbox] {
        width: 14px;
        height: 14px;
        margin-right: 8px;
    }

    .es-button {
        margin-top: 40px;

        &:last-child {
            margin-top: 0;
        }
    }
}
</style>
