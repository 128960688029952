<template>
    <div class="base-admin">
      <template v-if="isAdminAuthed">
        <AdminTabs></AdminTabs>
        <PromoStats v-if="cabinetActiveTab === 'stat'"></PromoStats>
        <AdminRefund v-if="cabinetActiveTab === 'refunds'"></AdminRefund>
        <AdminSettings v-if="cabinetActiveTab === 'settings'"></AdminSettings>
        <BaseWebinars v-if="cabinetActiveTab === 'webinars'"></BaseWebinars>
        <button type="button" class="es-button es-button--bordered red exit-button" @click="exit">
          Выйти из админки
        </button>
      </template>

      <AdminAuth v-else/>
    </div>
</template>

<script>
import PromoStats from './PromoStats.vue';
import AdminAuth from './AdminAuth.vue';
import AdminTabs from './AdminTabs.vue';
import AdminRefund from './AdminRefund.vue';
import AdminSettings from './AdminSettings.vue';
import BaseWebinars from './BaseWebinars.vue';

export default {
  name: 'BaseAdmin',
  components: {
    PromoStats,
    AdminAuth,
    AdminTabs,
    AdminRefund,
    AdminSettings,
    BaseWebinars,
  },
  data() {
    return {
      isAdminAuthed: false,
    };
  },
  computed: {
    adminToken() {
      return this.$store.state.user.adminToken;
    },
    cabinetActiveTab() {
      return this.$store.state.admin.cabinetActiveTab;
    },
  },
  watch: {
    adminToken() {
      if (this.adminToken !== '') {
        this.isAdminAuthed = true;
      }
    },
  },
  methods: {
    getAdminData() {
      const adminData = JSON.parse(localStorage.getItem('meditationAdmin'));

      this.$store.commit('setAdminToken', adminData.token);
    },
    exit() {
      localStorage.removeItem('meditationAdmin');

      document.location.reload();
    },
  },
  mounted() {
    if (localStorage.getItem('meditationAdmin')) {
      this.isAdminAuthed = true;
      this.getAdminData();
    }
  },
};
</script>

<style scoped lang="scss">
 @import "../../assets/scss/common/variables";
 @import "../../assets/scss/common/forms";

.base-admin {
  margin: 40px 0 0;

  .exit-button {
    margin: 50px 20px 15px 20px;
    width: calc(100% - 40px);
      box-shadow: none;
  }
}
</style>
