<template>
    <div class="admin-tabs">
        <div class="admin-tabs__item"
             @click="setActiveTab('stat')"
             :class="{'isActive': cabinetActiveTab === 'stat'}"
        >
            Статистика
        </div>
        <div class="admin-tabs__item"
             @click="setActiveTab('refunds')"
             :class="{'isActive': cabinetActiveTab === 'refunds'}"
        >
            Пользователи
        </div>
        <div class="admin-tabs__item"
             @click="setActiveTab('settings')"
             :class="{'isActive': cabinetActiveTab === 'settings'}"
        >
            Настройки
        </div>
        <div class="admin-tabs__item"
             @click="setActiveTab('webinars')"
             :class="{'isActive': cabinetActiveTab === 'webinars'}"
        >
            Вебинары
        </div>
    </div>
</template>

<script>

export default {
  name: 'AdminTabs',
  computed: {
    cabinetActiveTab() {
      return this.$store.state.admin.cabinetActiveTab;
    },
  },
  methods: {
    setActiveTab(tabName) {
      this.$store.commit('setActiveTab', tabName);
    },
  },
};
</script>

<style scoped lang="scss">
 @import "../../assets/scss/common/variables";

.admin-tabs {
    margin: 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    overflow-x: auto;

    @media only screen and (max-width: 767px) {
        justify-content: flex-start;
        padding: 0 24px 10px 24px;
    }

    &__item {
        border: solid 1px $main-blue;
        padding: 0 15px;
        height: 40px;
        line-height: 40px;
        cursor: pointer;
        border-left: 0;

        &.isActive {
            background: $main-blue;
            color: $white;
        }

        &:first-child {
            border-radius: 8px 0 0 8px;
            border-left: solid 1px $main-blue;
        }

        &:last-child {
            border-radius: 0 8px 8px 0;
        }
    }
}
</style>
