<template>
    <div class="purchase-info">
        <p>
            Приветствую! Рад представить самую первую
            версию моего проекта. Это MVP (минимально жизнеспособный продукт),
            и я сейчас работаю над его улучшением.
            Хочу сделать вам подарок и вместо 3-х месяцев подарить
            доступ на 6 месяцев. </p>
        <p>
            Взамен прошу вас активно участвовать
            в развитии проекта. Нажимайте
            на кнопки “Полезно” и “Бесполезно”,
            чтобы давать мне обратную связь.
            Переходите по ссылкам ниже, чтобы начать.</p>
    </div>
</template>

<script>
export default {
  name: 'CabinetPurchaseInfo',
  components: {
  },
  data() {
    return {
    };
  },
  methods: {
    wordForm(num, word) {
      const cases = [2, 0, 1, 1, 1, 2];
      return word[(num % 100 > 4 && num % 100 < 20) ? 2 : cases[(num % 10 < 5) ? num % 10 : 5]];
    },
  },
  computed: {
    consultationCount() {
      return this.$store.state.user.consultationCount;
    },
    meditationsInfo() {
      return this.$store.getters.getMeditationsInfoForSingle;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../../assets/scss/common/variables";

.purchase-info {
    background: $white;
    padding: 5px 24px;
    font-size: 18px;
    line-height: 28px;
    text-align: center;

    p {
        margin: 25px 0;
    }

    &__actions {
        .es-button {
            max-width: 250px;
            margin: 30px auto 0 auto;
        }
    }

    @media only screen and (max-width: 767px) {
        font-size: 14px;
        line-height: 21px;
        text-align: left;
    }
}
</style>
