<template>
    <div class="module-card__item" :class="{'blue': isBlue}">

        <div class="module-card__image">
            <img :src="data.groupImage"
                 :class="{'largeMarginTop': data.groupName === 'ya-skoro-budu-mamoy'}"
            >
        </div>

        <div class="module-card__content">
            <div class="module-card__avialible" v-if="priceBaseSale === 0">
                Вам доступен модуль
            </div>

            <div class="module-card__title">{{data.groupTitle}}</div>

            <div class="module-card__descr">
                {{data.groupDescr}}
            </div>

            <template v-if="priceBaseSale > 0">
                <ul class="module-card__list"
                    v-if="priceBaseSale > 0"
                    :class="{'hidden': isDescrHidden}"
                >
                    <li v-for="(meditation, index) in data.meditations" :key="index">
                        Медитация "{{meditation}}"
                    </li>
                    <li v-for="(webinar, index) in data.webinars" :key="`webinar_${index}`">
                        Вебинар "{{webinar}}"
                    </li>
                </ul>

                <div class="module-card__show-list"
                     @click="showDescr()"
                     :class="{'hidden': isDescrHidden}"
                >
                    <template v-if="isDescrHidden">Что входит в модуль</template>
                    <template v-else>Свернуть</template>
                </div>
            </template>

            <div class="module-card__price-cover" v-if="priceBaseSale > 0">
                <div class="module-card__price-item">
                    <div class="module-card__price-descr black">
                        Обычная цена
                    </div>
                    <div class="module-card__price black small"
                         :class="{'strikethrough': priceBase > priceBaseSale}"
                    >
                        {{numberWithSpaces(priceBase)}} <span class="curr-icon">₽</span>
                    </div>
                </div>
                <div class="module-card__price-item">
                    <div class="module-card__price-descr yellow">
                        Ваша цена
                    </div>
                    <div class="module-card__price yellow"
                         :class="{'strikethrough': priceBase === priceBaseSale}"
                    >
                        {{numberWithSpaces(priceBaseSale)}} <span class="curr-icon">₽</span>
                    </div>
                </div>
            </div>
        </div>

        <button class="es-button"
                v-if="priceBaseSale > 0"
                @click="
                startPayment(priceBaseSale, priceBase, 'module',  data.groupName, data.isFullModule)
                "
        >
            Получить доступ
        </button>
        <router-link
                class="es-button"
                :to="data.groupLink"
                v-else
        >
            Перейти
        </router-link>
    </div>
</template>

<script>

export default {
  name: 'ModulePaymentItem',
  props: {
    data: Object,
    isBlue: Boolean,
  },
  components: {
  },
  data() {
    return {
      complete: false,
      isUserFromWebinar: this.$store.state.user.landingUserType === 'baseFromWebinar',
      isUserAuthed: this.$store.state.user.landingUserType === 'baseAuthed',
      priceBase: this.data.noPayedPrice,
      priceBaseSale: Math.round(this.data.noPayedPrice / 3),
      isDescrHidden: true,
    };
  },
  computed: {
    isLandingSaleTimeOver() {
      return this.$store.state.user.isLandingSaleTimeOver;
    },
    meditationsInfo() {
      return this.$store.getters.getMeditationsInfoForCourse;
    },
    isSettingsReady() {
      return this.$store.state.general.isSettingsReady;
    },
  },
  mounted() {
    if (this.isSettingsReady) {
      this.checkSaleTimeTimer();
    }
  },
  watch: {
    isSettingsReady() {
      if (this.isSettingsReady) {
        this.checkSaleTimeTimer();
      }
    },
  },
  methods: {
    startPayment(priceToPay, priceOld, type, moduleName, isFullModule) {
      this.$store.commit('markModuleMeditationsForPay', moduleName);
      this.$store.commit('setModulePagePayment', true);
      this.$store.commit('setModuleLenght', isFullModule);
      this.$store.commit('setLandingPaymentPriceToPay', priceToPay);
      this.$store.commit('setLandingPaymentType', type);
      this.$store.commit('setPriceToPay', priceToPay);
      this.$store.commit('setLandingPaymentFullPrice', priceOld);
      this.$store.commit('showPayment');
    },
    numberWithSpaces(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    },
    checkSaleTimeTimer() {
      const endModulePayment3Days = localStorage.getItem('modulePayment3Days');

      if (endModulePayment3Days) {
        const currentTime = Date.parse(new Date());
        const endTimeTimeStamp = Date.parse(endModulePayment3Days.replace('-', '/').replace('-', '/'));

        if (currentTime > endTimeTimeStamp) {
          this.priceBaseSale = this.priceBase;
        }
      }
    },
    showDescr() {
      this.isDescrHidden = !this.isDescrHidden;
    },
  },
};
</script>

<style scoped lang="scss">
 @import "../../../assets/scss/common/variables";
 @import "../../../assets/scss/common/landing";
 @import "../../../assets/scss/common/forms";

.module-card {
    &__content {
        background: $white;
        border-radius: 10px 10px 0 0;
        padding: 30px 16px 1px 16px;
        margin: -60px 0 0 0;
        position: relative;
        z-index: 8;
    }

    &__avialible {
        position: absolute;
        height: 31px;
        line-height: 31px;
        color: $white;
        left: 0;
        top: -15px;
        padding: 0 24px;
        border-radius: 0 16px 16px 0;
        font-size: 14px;
        font-weight: 500;
        background: #1AC655;
    }

    &__descr {
        font-size: 18px;
        line-height: 26px;
        margin: 15px 0 5px 0;
        font-weight: 500;

        @media only screen and (max-width: 767px) {
            font-size: 14px;
            line-height: 21px;
        }
    }

    &__image {
        margin:0 0 30px 0;
        border-radius: 10px 10px 0 0;
        max-height: 350px;
        overflow: hidden;

        @media only screen and (max-width: 767px) {
            max-height: 237px;
        }

        img {
            max-width: 100%;
            border-radius: 10px 10px 0 0;
            margin-top: -25px;

            &.largeMarginTop {
                margin-top: -75px;
            }
        }
    }

    &__show-list {
        font-size: 18px;
        color: $main-blue;
        padding: 10px 20px 15px 0;
        cursor: pointer;
        display: inline-block;

        &.hidden:after {
            transform: rotate(0);
        }

        &:after {
            background: url(/images/landing/arrow-blue.svg);
            content: '';
            width: 12px;
            height: 7px;
            display: inline-block;
            margin-left: 8px;
            transform: rotate(180deg);
        }

        @media only screen and (max-width: 767px) {
            font-size: 14px;
        }
    }

    &__item {
        border: 2px solid #DCDFE7;
        box-sizing: border-box;
        border-radius: 10px;
        margin: 10px 0 20px 0;
        width: 100%;
        max-width: 450px;
        padding-bottom: 60px;
        position: relative;

        @media only screen and (max-width: 767px) {
            width: auto;
            margin: 10px 0 10px 0;
        }

        &:last-child {
            margin-right: 0;
        }

        .es-button {
            font-size: 16px;
            font-weight: 600;
            font-family: $titleFont;
            position: absolute;
            bottom: 20px;
            left: 16px;
            width: calc(100% - 32px);
            margin: auto;

            @media only screen and (max-width: 767px) {
                font-size: 12px;
            }
        }
    }

    &__title {
        font-size: 22px;
        font-weight: 600;
        color: $main-blue;
        margin-bottom: 30px;

        @media only screen and (max-width: 767px) {
            font-size: 18px;
            margin-bottom: 20px;
        }
    }

    &__price-cover {
        display: flex;
        justify-content: space-around;
        margin: 20px 0 0 0;
    }

    &__price-descr {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 7px;
        text-align: center;
        display: block;

        @media only screen and (max-width: 767px) {
            font-size: 14px;
        }

        &.black {
            color: $black;
        }

        &.yellow {
            color: #EEE61E;
        }
    }

    &__price {
        font-size: 38px;
        font-weight: 600;
        text-align: center;
        color: $main-blue;
        margin-bottom: 30px;

        @media only screen and (max-width: 767px) {
            font-size: 32px;
        }

        .curr-icon {
            font-size: 33px;

            @media only screen and (max-width: 767px) {
                font-size: 24px;
            }
        }

        &.black {
            color: $black;
        }

        &.yellow {
            color: #EEE61E;
        }

        &.small {
            font-size: 28px;

            @media only screen and (max-width: 767px) {
                font-size: 21px;
            }

            .curr-icon {
                font-size: 26px;

                @media only screen and (max-width: 767px) {
                    font-size: 22px;
                }
            }
        }

        &.strikethrough {
            text-decoration: line-through;

            &.black {
                color: $black;
            }
        }
    }

    &__list {
        overflow: hidden;
        transition: all 200ms;
        max-height: 1000px;
        padding: 15px 0 10px 10px;

        @media only screen and (max-width: 767px) {
            margin-bottom: 1px;
        }

        &.hidden {
            max-height: 0;
            padding: 0 0 0 10px;
        }

        li {
            list-style: none;
            padding-left: 30px;
            position: relative;
            font-size: 18px;
            line-height: 24px;
            font-weight: 500;
            margin-bottom: 20px;

            @media only screen and (max-width: 767px) {
               padding-left: 16px;
                font-size: 14px;
                line-height: 21px;
                margin-bottom: 15px;
            }

            &:last-child {
                margin-bottom: 0;
            }

            .light {
                font-weight: 400;
                line-height: 30px;

                @media only screen and (max-width: 767px) {
                    line-height: 26px;
                }
            }

            &:before {
                position: absolute;
                width: 15px;
                height: 11px;
                background: url("/images/landing/check-icon-blue.svg");
                content: '';
                left: 0;
                top: 8px;

                @media only screen and (max-width: 767px) {
                   left: -12px;
                    top: 4px;
                    transform: scale(0.7);
                }
            }
        }
    }
}
</style>
