<template>
  <div class="landing-page">
    <BaseSupermodule></BaseSupermodule>
  </div>
</template>

<script>
// @ is an alias to /src
import BaseSupermodule from '@/components/secondary-pages/BaseSupermodule.vue';

export default {
  name: 'Supermodule',
  components: {
    BaseSupermodule,
  },
};
</script>
