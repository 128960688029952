<template>
    <footer>
        © Андрей Сягло. 2021
    </footer>
</template>

<script>

export default {
  name: 'Footer',
  components: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  lang="scss">
@import "../../assets/scss/common/variables";

footer {
    height: 74px;
    line-height: 74px;
    text-align: center;
    background: #1d1d1d;
    color: #9B9B9B;
    font-size: 12px;
}

</style>
