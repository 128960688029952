<template>
    <div class="welcome-header">
        <div class="welcome-header__content">
            <h1>
                Второй пот<span class="dot-top">о</span>к<br>
                и<span class="dot-bottom">с</span>целения
            </h1>

            <div class="welcome-header__descr" v-if="isFlowSuccess">
                Вы купили участие в качестве зрителя.
            </div>
            <div class="welcome-header__descr" v-else>
                Волшебное пространство,
                где происходят массовые
                метаморфозы сознания
            </div>
            <div class="welcome-header__start-block">
                <WebinarStartCounter></WebinarStartCounter>
            </div>
        </div>
    </div>
</template>

<script>
import WebinarStartCounter from '@/components/secondary-pages/webinar-landing/WebinarStartCounter.vue';

export default {
  name: 'WelcomeLandingHeader',
  components: {
    WebinarStartCounter,
  },
  data() {
    return {
      isUserAuthed: this.$store.state.user.landingUserType === 'baseAuthed',
    };
  },
  computed: {
    getUserMail() {
      return this.$store.getters.getUserMail;
    },
    isFlowSuccess() {
      return this.$route.name === 'WebinarFlowSuccess';
    },
  },
  methods: {
  },
};
</script>

<style scoped lang="scss">
 @import "../../../assets/scss/common/variables";
 @import "../../../assets/scss/common/forms";

.welcome-header {
    min-height: 520px;
    background: url("/images/landing/welcome-header-webinar.jpg") center center;
    background-size: cover;
    margin-top: 45px;
    padding-top: 200px;
    box-sizing: border-box;
    text-align: center;
    color: $white;
    position: relative;
    padding-bottom: 1px;

    &__buy-more {
        background: $main-blue;
        max-width: 470px;
        margin: 20px auto;
        padding: 20px 40px 10px 40px;
        color: $white;
        text-align: center;
        border-radius: 10px;
        box-sizing: border-box;
        width: calc(100% - 50px);

        @media only screen and (max-width: 767px) {
            padding: 16px;
        }

        p {
            margin-bottom: 1px;
            line-height: 17px;
            font-size: 13px;
            font-weight: 500;

            @media only screen and (max-width: 767px) {
               margin-bottom: 5px;
            }

            strong {
                font-weight: 600;
                color: #eee61e;
            }

            @media only screen and (max-width: 450px) {
               text-align: left;
            }
        }

        button {
            width: 170px!important;

            @media only screen and (max-width: 450px) {
                width: 100%!important;
            }
        }
    }

    &__content {
        position: relative;
        z-index: 5;
    }

    &:before {
        position: absolute;
        bottom: 0;
        left: 0;
        height: 360px;
        width: 100%;
        content: '';
        background: linear-gradient(1.82deg, rgba(0, 0, 0, 0.35) 70.27%, rgba(0, 0, 0, 0) 89.42%);
    }

    h1 {
        font-weight: 600;
        font-size: 46px;
        color: $white;
        line-height: 54px;
        letter-spacing: 1px;

        @media only screen and (max-width: 767px) {
            font-size: 34px;
            line-height: 40px;
        }

        .dot-top,
        .dot-bottom {
            position: relative;

            &:before {
                width: 7px;
                height: 7px;
                position: absolute;
                content: '';
                top: 3px;
                left: 12px;
                background: $white;
                border-radius: 50%;

                @media only screen and (max-width: 767px) {
                    width: 5px;
                    height: 5px;
                    left: 8px;
                }
            }
        }

        .dot-bottom:before {
            top: auto;
            bottom: -2px;
        }
    }

    &__descr {
        font-size: 15px;
        line-height: 21px;
        max-width: 500px;
        margin: 15px auto;
        font-weight: 500;
        width: calc(100% - 80px);

        br {
            display: none;

            &.isMobile {
                @media only screen and (max-width: 767px) {
                    display: block;
                }
            }

            &.isDesktop {
                @media only screen and (min-width: 768px) {
                    display: block;
                }
            }
        }

        a {
            background: $main-blue;
            color: $white;
            padding: 0 5px;
        }
    }

    &__start-block {
        text-align: center;

        button {
            display: inline-block;
            width: auto;
            padding: 0 25px;
            cursor: pointer;

            @media only screen and (max-width: 450px) {
                width: calc(100% - 50px);
            }
        }
    }
}
</style>
