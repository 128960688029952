<template>
  <div class="sammary-page">
    <div class="sammary-page__content">
      <div class="sammary-page__title">
        Елена создала для вас
        <span class="blue">конспект</span> вебинара.
      </div>
      <div class="sammary-page__descr">
        Скачайте по ссылке и сохраните себе на телефон.
      </div>

      <div class="sammary-page__download">
        <a href="https://elena.shop/summary.pdf" target="_blank"  download class="es-button">Скачать конспект</a>
      </div>

      <div class="sammary-page__additional">
        <div class="sammary-page__additional-title">
          Внимание! В течение 24 часов после вебинара
          для вас действует <span class="blue">специальная цена</span> на
          покупку курса "Медитация в новую жизнь".
        </div>

        <div class="sammary-page__additional-text">
          Переходите по <router-link to="/welcomeWebinar" class="link">ссылке</router-link><br>
          чтобы воспользоваться предложением.
        </div>
      </div>
    </div>

    <Footer></Footer>
  </div>
</template>

<script>
// @ is an alias to /src
import Footer from '@/components/general/Footer.vue';

export default {
  name: 'Sammary',
  components: {
    Footer,
  },
  beforeDestroy() {
    document.documentElement.style.setProperty('--main-bg-color', '#ffffff');
  },
  beforeMount() {
    document.documentElement.style.setProperty('--main-bg-color', '#fff4f1');
  },
};
</script>

<style scoped lang="scss">
  @import "../assets/scss/common/forms";
  @import "../assets/scss/common/variables";

  .sammary-page {
    padding: 60px 24px 0 24px;
    background: $white;
    min-height: 100vh;
    box-sizing: border-box;
    background: url('/images/landing/landing-bg-circles.jpg') no-repeat 66% 40px #fff;

    @media only screen and (max-width: 767px) {
      padding: 30px 24px 0 24px;
      background: url('/images/landing/landing-bg-circles-mobile.svg') no-repeat 0 0 #fff;
    }

    &__content {
      min-height: calc(100vh - 164px);

      @media only screen and (max-width: 767px) {
        min-height: calc(100vh - 134px);
      }
    }

    .blue {
      color: $main-blue;
    }

    .link {
      display: inline-block;
      background: $main-yellow;
      border-radius: 5px;
      text-decoration: none;
      padding: 0 10px;
      margin: 0 5px;
      font-weight: 600;
      color: $black;
    }

    footer {
      margin: 30px -24px 0 -24px;
    }

    &__additional-title {
      font-weight: 600;
      max-width: 490px;
      margin: 0 auto 25px auto;
    }

    &__additional-text {
      max-width: 490px;
      margin: 0 auto;
    }

    &__additional {
      border: solid $main-blue 1px;
      background: $light-blue;
      border-radius: 8px;
      padding: 20px;
      margin-top: 40px;
      position: relative;
      text-align: center;
      font-size: 18px;
      line-height: 28px;

      @media only screen and (max-width: 767px) {
        font-size: 14px;
        line-height: 21px;
      }

      &:before {
        position: absolute;
        content: '';
        background: url("/images/landing/bant-blue.svg");
        width: 42px;
        height: 22px;
        top: -22px;
        left: 50%;
        margin-left: -21px;
      }
    }

    &__title {
      font-weight: 600;
      font-size: 35px;
      margin-bottom: 40px;
      line-height: 50px;

      @media only screen and (max-width: 767px) {
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 10px;
      }
    }

    &__descr {
      font-size: 18px;
      line-height: 28px;
      margin: 0 auto;

      @media only screen and (max-width: 767px) {
        font-size: 14px;
        line-height: 21px;
      }
    }

    &__download {
      padding: 20px 0;
      margin: 0 auto;
      max-width: 270px;

      .es-button {
        height: 50px;
        line-height: 50px;
        font-size: 16px;
        font-weight: 600;
        font-family: $titleFont;
      }
    }
  }
</style>
