<template>
    <div class="welcome-page">
        <Header isNotFixed="true"></Header>
        <WelcomeLandingHeader></WelcomeLandingHeader>
        <SinglePayment></SinglePayment>
        <WelcomeLandingFAQ isNoStartButton="true"></WelcomeLandingFAQ>
        <Footer></Footer>
    </div>
</template>

<script>
import Header from '@/components/header/BaseHeader.vue';
import Footer from '@/components/general/Footer.vue';
import WelcomeLandingHeader from '@/components/secondary-pages/welcome-landing/WelcomeLandingHeader.vue';
import WelcomeLandingFAQ from '@/components/secondary-pages/welcome-landing/WelcomeLandingFAQ.vue';
import SinglePayment from '@/components/secondary-pages/welcome-landing/SinglePayment.vue';

export default {
  name: 'BaseFinalprice',
  components: {
    Header,
    WelcomeLandingHeader,
    SinglePayment,
    WelcomeLandingFAQ,
    Footer,
  },
  data() {
    return {
      webinarInvite: '',
      isInviteShortCounterUsed: false,
    };
  },
  computed: {
    getUserMail() {
      return this.$store.getters.getUserMail;
    },
    meditationsInfo() {
      return this.$store.getters.getMeditationsInfoForCourse;
    },
  },
  mounted() {

  },
  methods: {
  },
  beforeDestroy() {
    this.$store.commit('setLandingUserType', '');
    document.documentElement.style.setProperty('--main-bg-color', '#ffffff');
  },
  beforeMount() {
    let userType = 'finalprice';

    if (this.meditationsInfo.priceForNoPayed === 0) {
      this.$router.push({ name: 'CabinetFull' });
    }

    if (this.getUserMail !== '') {
      userType = 'baseAuthedFinalPrice';
    }

    this.$store.commit('setLandingUserType', userType);

    document.documentElement.style.setProperty('--main-bg-color', '#fff4f1');
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../../assets/scss/common/variables";

.welcome-page {
    .base-header {
        background: $white;
    }
}
</style>
