<template>
  <div class="landing-page">13213
    <BaseWebinarLanding></BaseWebinarLanding>
  </div>
</template>

<script>
// @ is an alias to /src
import BaseWebinarLanding from '@/components/secondary-pages/BaseWebinarLanding.vue';

export default {
  name: 'HomeWebinar',
  components: {
    BaseWebinarLanding,
  },
};
</script>
