<template>
  <div class="webinar-instr">
      <div class="webinar-instr__content">
          <div class="webinar-instr__title-large">
              Вебинар “От бесплодия к счастливому материнству”
          </div>
          <div class="webinar-instr__subscribe" v-if="isMessengerActive">
              <div class="webinar-instr__title">Остался последний шаг</div>
              <div class="webinar-instr__descr">
                  <p>
                      Выберите, куда прислать ссылку: в телеграм или
                      в вайбер.
                  </p>

                  <div class="webinar-instr__subscribe-variants">
                      <a :href="tlink" class="webinar-instr__subscribe-item">
                          <div class="webinar-instr__subscribe-image">
                              <img src="/images/icon-telegram.svg"/>
                          </div>
                          <div class="webinar-instr__subscribe-text">Telegramm</div>
                      </a>
                      <a :href="vlink" class="webinar-instr__subscribe-item">
                          <div class="webinar-instr__subscribe-image">
                              <img src="/images/icon-viber.svg"/>
                          </div>
                          <div class="webinar-instr__subscribe-text">Viber</div>
                      </a>
                  </div>

                  <p>
                      Мы пришлем вам ссылку для входа и напоминание,
                      чтобы вы не пропустили вебинар и получили максимум пользы от него.
                  </p>
              </div>
          </div>

          <div class="webinar-instr__has-problems"
               @click="showAddintionalContent()"
               v-if="isMessengerActive"
          >
              У вас нет вайбера и телеграма?
          </div>

          <template v-if="additionalContent">
              <p class="webinar-instr__additional-title">
                  <template v-if="isMessengerActive">
                      Если вы не получили ссылку в мессенджер или у
                      вас нет ни вайбера, ни телеграма,
                      тогда сделайте 3 простых шага, чтобы попасть на вебинар:
                  </template>
                  <template v-else>
                      Сделайте 3 простых шага, чтобы попасть на вебинар:
                  </template>
              </p>

              <div class="webinar-instr__descr">
                  <ol>
                      <li>
                          <span class="li-count">1.</span>
                          Скопируйте ссылку: <span class="blue">{{link}}</span><br>
                          или нажмите кнопку ниже

                          <button
                                  @click="copyContent(link)"
                                  class="es-button webinar-instr__button"
                          >
                              Скопировать ссылку
                          </button>
                      </li>
                      <li>
                          <span class="li-count">2.</span> Откройте любой браузер на своем телефоне

                          <img src="/images/browser-logos.jpg?v2" class="webinar-instr__img-small">
                      </li>
                      <li>
                          <span class="li-count">3.</span>

                          <div class="webinar-instr__title-broser-example">
                              Вставьте ссылку в адресную строку <br class="mobileOnly"> браузера
                          </div>

                          <img src="/images/browser-example.jpg?v1"
                               class="webinar-instr__img-browser-example"
                          >
                      </li>
                  </ol>
              </div>

              <div class="webinar-instr__subscribe">
                  <div class="webinar-instr__title">Как не пропустить вебинар?</div>
                  <div class="webinar-instr__descr">
                      <p>
                          Поставьте напоминание себе в календарь:
                      </p>

                      <div class="webinar-instr__subscribe-variants">
                          <a :href="getGoogleLink()"
                             target="_blank"
                             class="webinar-instr__subscribe-item"
                          >
                              <div class="webinar-instr__subscribe-image">
                                  <img src="/images/icon-gmail.png"/>
                              </div>
                              <div class="webinar-instr__subscribe-text">Google Calendar</div>
                          </a>
                          <div class="webinar-instr__subscribe-item"  @click="addEventToCalendar()">
                              <div class="webinar-instr__subscribe-image">
                                  <img src="/images/icon-google-calendar.svg"/>
                              </div>
                              <div class="webinar-instr__subscribe-text">Calendar</div>
                          </div>
                      </div>

                      <p>
                          Мы пришлем напоминание за 5 минут до начала,
                          чтобы вы не пропустили наш вебинар и получили максимум пользы от него.
                      </p>
                  </div>
              </div>
          </template>
      </div>

      <Footer></Footer>
  </div>
</template>

<script>
// @ is an alias to /src
import copy from 'clipboard-copy';
import { ICalendar, GoogleCalendar } from 'datebook';
import Footer from '@/components/general/Footer.vue';

export default {
  name: 'WebinarInstractions',
  components: {
    Footer,
  },
  data() {
    return {
      link: 'https://start.bizon365.ru/room/93064/koabmhuss',
      tlink: 'tg://resolve?domain=elena_psybot&start=ml2',
      vlink: 'viber://pa?chatURI=elena-psy&context=ml2',
      icalendar: '',
      googleCalendar: '',
      additionalContent: false,
    };
  },
  computed: {
    isMessengerActive() {
      return this.$store.state.general.isMessengersActive;
    },
  },
  mounted() {
    if (!this.isMessengerActive) {
      this.additionalContent = true;
    }
  },
  watch: {
    isMessengerActive() {
      if (!this.isMessengerActive) {
        this.additionalContent = true;
      } else {
        this.additionalContent = false;
      }
    },
  },
  beforeMount() {
    document.documentElement.style.setProperty('--main-bg-color', '#fff4f1');

    if (this.$route.name === 'WebinarInstructionsAsia') {
      this.link = 'https://start.bizon365.ru/room/93064/kowog6sx20';
      this.tlink = 'tg://resolve?domain=elena_psybot&start=ml3';
      this.vlink = 'viber://pa?chatURI=elena-psy&context=ml3';
    }

    this.setCalendarOptions();
  },
  methods: {
    showAddintionalContent() {
      this.additionalContent = !this.additionalContent;
    },
    setCalendarOptions() {
      const options = {
        title: 'Вебинар: "От бесплодия к счастливому материнству."',
        location: this.link,
        description: `Чтобы подключиться к вебинару, перейдите по ссылке: ${this.link}`,
        start: new Date(this.getDates().start),
        end: new Date(this.getDates().end),
      };

      this.icalendar = new ICalendar(options);
      this.googleCalendar = new GoogleCalendar(options);

      this.icalendar.addAlarm({
        action: 'DISPLAY',
        trigger: {
          minutes: 10,
        },
      });
    },
    getGoogleLink() {
      return this.googleCalendar.render();
    },
    getDates() {
      const currentDate = new Date();
      const hours = currentDate.getHours();
      let startTime = 19;

      if (hours >= 19) {
        currentDate.setDate(currentDate.getDate() + 1);
      }

      if (this.$route.name === 'WebinarInstructionsAsia') {
        startTime = 15;
      }

      const year = currentDate.getFullYear();
      const endTime = startTime + 2;
      let day = currentDate.getDate();
      let month = currentDate.getMonth() + 1;
      let resultDates = '';

      if (month < 10) {
        month = `0${month}`;
      }

      if (day < 10) {
        day = `0${day}`;
      }

      resultDates = {
        start: `${year}-${month}-${day}T${startTime}:00:00+03:00`,
        end: `${year}-${month}-${day}T${endTime}:00:00+03:00`,
      };

      return resultDates;
    },
    addEventToCalendar() {
      this.icalendar.download();
    },
    copyContent(content) {
      const message = 'Ссылка скопирована';

      copy(content).then(() => {
        this.$store.commit('showGlobalMessage', message);
      });
    },
  },
};
</script>

<style scoped lang="scss">
  @import "../assets/scss/common/forms";
  @import "../assets/scss/common/variables";

  .webinar-instr{
    background: $white;
    min-height: 100vh;
    box-sizing: border-box;
    padding: 40px 24px 0 24px;
    background: url('/images/landing/landing-bg-circles.jpg') no-repeat 66% 65px #fff;

    @media only screen and (max-width: 767px) {
      padding: 20px 24px 0 24px;
      background: url('/images/landing/landing-bg-circles-mobile.svg') no-repeat 0 -20px #fff;
    }

    &__content {
        min-height: calc(100vh - 114px);

        @media only screen and (max-width: 767px) {
            min-height: calc(100vh - 95px);
        }
    }

    .base-header {
      @media only screen and (min-width: 768px) {
        margin-left: -24px;
      }
    }

      &__has-problems {
          margin: 30px 0 15px 0;
          padding-bottom: 4px;
          display: inline-block;
          border-bottom: dashed 1px $darkGray;
          font-size: 18px;
          font-weight: 600;
          cursor: pointer;
          opacity: 0.6;

          @media only screen and (max-width: 767px) {
              font-size: 16px;
          }
      }

      &__subscribe-variants {
          display: flex;
          justify-content: center;
          align-items: flex-start;
          margin: 25px 0;
      }

      &__subscribe-item {
          font-size: 9px;
          line-height: 14px;
          width: 60px;
          text-align: center;
          margin: 0 8px;
          cursor: pointer;
          display: block;
          text-decoration: none;
          color: $black;
      }

      &__subscribe-image {
          margin-bottom: 8px;

          img {
              width: 43px;
          }
      }

    &__button {
        height: 50px;
        width: 270px;
        font-size: 16px;
        margin: 30px auto 35px auto;

        @media only screen and (max-width: 767px) {
            margin: 20px auto 26px -25px;
            width: calc(100vw - 48px);
        }
    }

      footer {
          margin: 0 -24px;
      }

    &__additional-title {
        margin: 30px 0 45px 0;
        line-height: 24px;

        @media only screen and (max-width: 767px) {
           font-size: 14px;
            margin: 30px 0 20px 0;
        }
    }

    &__descr {
      font-size: 14px;
      line-height: 21px;
      margin: 0 auto;

        .link {
            color: $main-blue;
            cursor: pointer;
            text-decoration: none;
        }

        p {
            font-size: 16px;
            line-height: 20px;

            @media only screen and (max-width: 767px) {
                font-size: 14px;
            }
        }

        ol {
            margin: 30px 0 0 0;

            @media only screen and (max-width: 767px) {
                margin: 40px 0 0 0;
            }

            li {
                font-size: 16px;
                line-height: 26px;
                position: relative;
                padding-left: 35px;

                @media only screen and (max-width: 767px) {
                    font-size: 14px;
                    line-height: 21px;
                    padding-left: 25px;

                    @media only screen and (max-width: 767px) {
                        .blue {
                            font-size: 12px;
                        }
                    }

                }

                .li-count {
                    font-weight: 600;
                    color: $main-blue;
                    position: absolute;
                    left: 2px;
                    top: 0;
                    font-size: 22px;

                    @media only screen and (max-width: 767px) {
                        font-size: 18px;
                        left: 0;
                        top: 4px;
                    }
                }

            }
        }
    }

      &__subscribe {
          background: $light-blue;
          background: rgba(137, 150, 193, 0.1);
          padding: 20px 24px 30px 24px;
          margin: 50px -24px 0 -24px;

          @media only screen and (max-width: 767px) {
              padding: 5px 24px 20px 24px;
              margin: 0 -24px;
          }

          p {
              font-size: 16px;
              line-height: 26px;
              margin: 0 0 20px 0;

              @media only screen and (max-width: 767px) {
                  font-size: 13px;
                  line-height: 21px;
                  margin: 0 0 15px 0;
              }
          }
      }

    &__img-small {
      max-width: 300px;
      display: block;
      margin: 5px auto 30px auto;

        @media only screen and (max-width: 767px) {
            margin: 30px 0 40px -15px;
        }
    }

      &__img-browser-example {
          max-width: 290px;
          display: block;
          margin: 30px auto 40px auto;
          position: relative;
          z-index: 2;

          @media only screen and (max-width: 767px) {
              margin: 10px 0 40px 0;
          }
      }

      &__title-broser-example {
          position: relative;
          display: inline-block;
          z-index: 3;

          &:after {
              position: absolute;
              top: 10px;
              background: url("/images/arrow-red.svg");
              width: 47px;
              height: 42px;
              content: '';

              @media only screen and (max-width: 767px) {
                  top: 30px;
              }
          }
      }

    &__title-large {
        font-weight: 600;
        font-size: 35px;
        color: $main-blue;
        margin-bottom: 40px;
        line-height: 50px;

        @media only screen and (max-width: 767px) {
            font-size: 18px;
            line-height: 24px;
            margin-bottom: 10px;
        }
    }

    &__title {
      font-size: 22px;
      font-weight: 600;
      line-height: 28px;
      margin: 30px auto 20px auto;

        @media only screen and (max-width: 767px) {
            font-size: 18px;
            line-height: 24px;
            margin: 20px auto 10px auto;
        }
    }

    &__download {
      padding: 20px;
      width: 150px;
      margin: 0 auto;
    }

      .blue {
          color: $main-blue;
      }

      .mobileOnly {
          display: none;

          @media only screen and (max-width: 767px) {
              display: block;
          }
      }
  }
</style>
