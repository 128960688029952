<template>
    <header class="base-header-info">
        <button class="base-header-info__back" @click="$router.go(-1)"></button>
        <div class="base-header-info__title">ПОДРОБНЕЕ О КУРСЕ</div>
    </header>
</template>

<script>

export default {
  name: 'BaseHeader',
  props: {
    isNotFixed: String,
    isNoBurger: String,
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
  computed: {
  },
  methods: {
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/scss/common/variables";

.base-header-info {
    height: 60px;
    width: 100%;
    line-height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 10;
    padding-left: 24px;
    padding-right: 24px;
    box-sizing: border-box;
    max-width: $mobile-max;
    border-bottom: solid $lightGray 1px;
    position: fixed;
    top: 0;
    left: 0;
    background: $white;

    @media only screen and (min-width: 768px) {
        left: auto;
    }

    &__title {
        font-size: 24px;
        font-weight: 400;
        line-height: 29px;
        letter-spacing: 0.02em;

        @media only screen and (max-width: 767px) {
            font-size: 16px;
        }
    }

    &__back {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        position: relative;
        cursor: pointer;
        z-index: 10;
        background: url("/images/right-arrow.svg") $main-blue no-repeat 50% 50%;
        border: none;
    }
}
</style>
