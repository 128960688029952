<template>
  <div class="admin-page">
    <BaseAdmin></BaseAdmin>
  </div>
</template>

<script>
// @ is an alias to /src
import BaseAdmin from '@/components/admin/BaseAdmin.vue';

export default {
  name: 'AdminPage',
  components: {
    BaseAdmin,
  },
};
</script>
