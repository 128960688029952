<template>
    <div class="module-card__item">
        <div class="module-card__content">
            <div class="module-card__title">{{data.title}}</div>

            <ul class="module-card__list">
                <li v-for="(meditation, index) in data.list"
                    :key="index"
                    @click="toogleIsPayPending(meditation.id)"
                    :class="{
                        'isCompleted': meditation.isCompleted,
                        'isInProgress': meditation.isInProgress
                    }"
                >

                    <router-link
                            :to="`/tasks/${data.groupName}/${meditation.url}`"
                    >
                        "{{meditation.name}}"
                    </router-link>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>

export default {
  name: 'ModulePaymentItem',
  props: {
    data: Object,
  },
  components: {
  },
  data() {
    return {
      complete: false,
      isUserFromWebinar: this.$store.state.user.landingUserType === 'baseFromWebinar',
      isUserAuthed: this.$store.state.user.landingUserType === 'baseAuthed',
      isErrorVisible: false,
      saleCoof: 3,
    };
  },
  computed: {
    isCabinet() {
      return this.$route.name === 'Cabinet' || this.$route.name === 'CabinetFull';
    },
    meditationsInfo() {
      return this.$store.getters.getMeditationsInfoForCourse;
    },
    priceBaseSale() {
      return Math.round(this.data.priceForPending / this.saleCoof);
    },
    priceBase() {
      return this.data.priceForPending;
    },
    priceForPending() {
      return this.data.priceForPending;
    },
    isSettingsReady() {
      return this.$store.state.general.isSettingsReady;
    },
  },
  mounted() {
    if (this.isSettingsReady && !this.isCabinet) {
      this.checkSaleTimeTimer();
    }
  },
  watch: {
    isSettingsReady() {
      if (this.isSettingsReady && !this.isCabinet) {
        this.checkSaleTimeTimer();
      }
    },
  },
  methods: {
    getUserData() {
      this.$store.commit('setUpdateMeditationSource', 'cabinet');
      this.$store.dispatch('updateMeditations');
    },
    toogleIsPayPending(id) {
      this.isErrorVisible = false;
      this.$store.commit('markMeditationForPayByID', {
        id,
      });
    },
    startPayment(priceToPay, priceOld, type, moduleName) {
      if (this.priceBaseSale === 0) {
        this.isErrorVisible = true;
        return false;
      }

      this.$store.commit('markSingleMeditationsForPay', moduleName);
      this.$store.commit('setSinglePagePayment', true);
      this.$store.commit('setLandingPaymentPriceToPay', priceToPay);
      this.$store.commit('setLandingPaymentType', type);
      this.$store.commit('setPriceToPay', priceToPay);
      this.$store.commit('setLandingPaymentFullPrice', priceOld);
      this.$store.commit('showPayment');

      return true;
    },
    numberWithSpaces(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    },
    checkSaleTimeTimer() {
      const endModulePayment3Days = localStorage.getItem('singlePayment3Days');

      if (endModulePayment3Days) {
        const currentTime = Date.parse(new Date());
        const endTimeTimeStamp = Date.parse(endModulePayment3Days.replace('-', '/').replace('-', '/'));

        if (currentTime > endTimeTimeStamp) {
          this.saleCoof = 1;
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
 @import "../../../assets/scss/common/variables";
 @import "../../../assets/scss/common/landing";
 @import "../../../assets/scss/common/forms";

.module-card {
    &__reload {
        position: absolute;
        width: 22px;
        height: 22px;
        background: url("/images/reload-icon.svg") no-repeat;
        background-size: contain;
        right: 15px;
        top: 15px;
        padding: 0;
        border: 0;
        z-index: 10;
        cursor: pointer;
    }

    &__error {
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
        text-align: center;
        margin: 25px 0;
        color: $main-red;
    }

    &__content {
        background: $white;
        border-radius: 10px 10px 0 0;
        padding: 30px 16px 0 16px;
        position: relative;
        z-index: 8;
    }

    &__avialible {
        position: absolute;
        height: 31px;
        line-height: 31px;
        color: $white;
        left: 0;
        top: -15px;
        padding: 0 24px;
        border-radius: 0 16px 16px 0;
        font-size: 14px;
        font-weight: 500;
        background: #1AC655;
    }

    &__descr {
        font-size: 18px;
        line-height: 26px;
        margin: 15px 0 5px 0;
        font-weight: 500;

        @media only screen and (max-width: 767px) {
            font-size: 14px;
            line-height: 21px;
        }
    }

    &__item {
        border: 2px solid #DCDFE7;
        box-sizing: border-box;
        border-radius: 10px;
        overflow: hidden;
        margin: 10px 0 20px 0;
        width: 100%;
        position: relative;

        @media only screen and (max-width: 767px) {
            width: auto;
            margin: 10px 0 10px 0;
        }

        &:last-child {
            margin-right: 0;
        }

        .es-button {
            font-size: 16px;
            font-weight: 600;
            font-family: $titleFont;
            margin: auto;
            height: 50px;

            @media only screen and (max-width: 767px) {
                font-size: 12px;
            }

            &.disabled {
                background: $main-blue;
                opacity: 0.5;
            }
        }
    }

    &__title {
        font-size: 22px;
        font-weight: 600;
        color: $main-blue;
        margin-bottom: 30px;

        @media only screen and (max-width: 767px) {
            font-size: 18px;
            margin-bottom: 20px;
        }
    }

    &__price-cover {
        display: flex;
        justify-content: space-around;
        max-width: 450px;
        margin: auto auto 30px auto;
    }

    &__price-cover-block {
        margin: 20px -16px 0 -16px;
        border-radius: 12px 12px 0 0;
        padding: 24px 16px 24px 16px;
        background: #E6ECFF;

        &.isTransparent {
            background: transparent;
            padding-top: 4px;
        }
    }

    &__price-descr {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 7px;
        text-align: center;
        display: block;

        @media only screen and (max-width: 767px) {
            font-size: 14px;
        }

        &.black {
            color: $black;
        }

        &.yellow {
            color: #E3BE12;
        }
    }

    &__price {
        font-size: 38px;
        font-weight: 600;
        text-align: center;

        @media only screen and (max-width: 767px) {
            font-size: 32px;
        }

        .curr-icon {
            font-size: 33px;

            @media only screen and (max-width: 767px) {
                font-size: 24px;
            }
        }

        &.black {
            color: $black;
        }

        &.yellow {
            color: #E3BE12;
        }

        &.small {
            font-size: 28px;

            @media only screen and (max-width: 767px) {
                font-size: 21px;
            }

            .curr-icon {
                font-size: 26px;

                @media only screen and (max-width: 767px) {
                    font-size: 22px;
                }
            }
        }

        &.strikethrough {
            text-decoration: line-through;

            &.black {
                color: $black;
            }
        }
    }

    &__list {
        overflow: hidden;
        transition: all 200ms;
        max-height: 1000px;
        padding: 15px 0 18px 0;

        @media only screen and (max-width: 767px) {
            margin-bottom: 1px;
        }

        &.hidden {
            max-height: 0;
            padding: 0 0 0 10px;
        }

        .es-checkbox-label {
            width: 20px;
            height: 20px;
            border: solid $main-blue 1px;
            display: inline-block;
            position: absolute;
            left: 0;
            top: 1px;

            @media only screen and (max-width: 767px) {
                top: 4px;
            }

            &.isChecked {
                &:before {
                    position: absolute;
                    display: block;
                    content: '';
                    background: url("/images/landing/check-icon-blue.svg") no-repeat 50% 50%;
                    width: 100%;
                    height: 100%;
                    left: 0;
                    top: 0;
                }
            }
        }

        li.isCompleted {
            cursor: pointer;

            &:before {
                background: url("/images/check-icon-green.svg");
                border: 0;
                top: 4px;
            }
        }

        li.isInProgress {
            cursor: pointer;

            &:before {
                background: url("/images/check-icon-gray.svg") no-repeat 50% 0;
                border: 0;
                top: 4px;
            }
        }

        li {
            list-style: none;
            padding-left: 35px;
            position: relative;
            font-size: 16px;
            line-height: 24px;
            font-weight: 500;
            margin-bottom: 20px;

            &:before {
                position: absolute;
                width: 20px;
                height: 20px;
                border: solid rgba(0,0,0,0.15) 1px;
                content: '';
                left: 0;
                top: 1px;
                border-radius: 50%;
            }

            a {
                color: $main-blue;
            }

            @media only screen and (max-width: 767px) {
                font-size: 14px;
                line-height: 21px;
                margin-bottom: 15px;
            }

            &:last-child {
                margin-bottom: 0;
            }

            .light {
                font-weight: 400;
                line-height: 30px;

                @media only screen and (max-width: 767px) {
                    line-height: 26px;
                }
            }
        }
    }
}
</style>
