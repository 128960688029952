<template>
    <div class="wlm-review">
        <div class="landing-title-group">
            <div class="landing-title-group__mid">Поздравляю с регистрацией!</div>
        </div>

        <div class="wlm-review__text">
            Приходите на вебинар, ставьте себе напоминание,
            чтобы не пропустить. Рекомендую организовать вам
            свое пространство так, чтобы 2 часа вас никто не
            беспокоил и вы могли максимально полно усваивать информацию.
            Подготовьте ручку и блокнот, чтобы записывать свои инсайты во
            время вебинара. Подготовьте наушники, мы будем делать
            практики и вы сможете погрузиться в медитацию более глубоко.
            Будьте максимально открытыми к новым знаниям, до встречи!
        </div>

        <div class="wlm-review__video">
            <iframe
                    width="100%"
                    height="400"
                    src="https://www.youtube.com/embed/mVjrV8csTRA"
                    frameborder="0"
                    allowfullscreen
            ></iframe>
        </div>
    </div>
</template>

<script>

export default {
  name: 'WelcomeLandingReviews',
  data() {
    return {
      complete: false,
    };
  },
  computed: {
  },
  methods: {
  },
};
</script>

<style scoped lang="scss">
 @import "../../../assets/scss/common/variables";
 @import "../../../assets/scss/common/landing";

.wlm-review {
    padding: 30px 24px 50px 24px;
    background: $light-blue;
    min-height: calc(100vh - 194px);

    @media only screen and (max-width: 767px) {
        padding: 30px 24px 20px 24px;
    }

    .landing-title-group__mid {
        line-height: 24px;
        font-size: 20px;
    }

    &__video {
        max-width: 320px;
        margin: auto;
    }

    &__text {
        font-size: 14px;
        line-height: 21px;
        margin: 40px 0 50px 0;
    }
}
</style>
