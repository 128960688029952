<template>
    <div class="admin-refund">
        <div class="admin-refund__title">Введите почту пользователя</div>
        <form @submit.prevent="getUserData()">
            <div class="es-input-cover">
                <input type="email"
                       required
                       v-model="mail"
                       placeholder="Почта пользователя"
                       class="es-input"
                       @focus="isInputFocused = true"
                       @blur="hideHints()"
                >
                <div class="es-hints" v-show="emailHints.length > 0 && isInputFocused" >
                    <div class="es-hints__item"
                         v-for="(hint, index) in emailHints"
                         :key="index"
                         @click="setValueFromHint(hint.email)"
                    >
                        {{hint.email}}
                    </div>
                </div>
            </div>

            <button type="submit" class="es-button">Получить данные пользователя</button>
        </form>

        <div class="admin-refund__info" v-if="hasUserData">
            <PurchaseList></PurchaseList>

            <div class="admin-refund__title">Информация о пользователе</div>
            <div class="admin-refund__user-data">
                <table>
                    <tr>
                        <th>Код восстанволения</th>
                        <td>{{userData.confirm_code}}</td>
                    </tr>
                    <tr>
                        <th>Покупки</th>
                        <td>{{userData.purchases}}</td>
                    </tr>
                    <tr>
                        <th>Пакеты</th>
                        <td>{{userData.packages}}</td>
                    </tr>
                    <tr>
                        <th>id пользователя</th>
                        <td>{{userData.id}}</td>
                    </tr>
                    <tr>
                        <th>Количество восстановлений</th>
                        <td>{{userData.restore_count}}</td>
                    </tr>
                    <tr>
                        <th>Почта</th>
                        <td>{{userData.email}}</td>
                    </tr>
                    <tr>
                        <th>Оплаты</th>
                        <td>
                            <div class="admin-refund__payment-item"
                                 v-for="(item, index) in getReadablePaymentLog(userData.paymentLog)"
                                 :key="index"
                                 :class="{'adminAdited': asAdmin(item.event)}"
                            >
                                <p><strong>Дата события:</strong> {{item.dateTime}}</p>
                                <p><strong>Тип события:</strong> {{item.event}}</p>
                                <p>
                                    <strong v-if="item.event === 'Users payment'">
                                        Список покупок после оплаты:
                                    </strong>
                                    <strong  v-if="item.event === 'Data changed by admin'">
                                        Список покупок после изменения:
                                    </strong>
                                    <strong  v-if="item.event === 'Data cleared by admin'">
                                        Список покупок до очистки:
                                    </strong>
                                    {{item.data}}
                                </p>
                                <p>
                                    <strong v-if="item.event === 'Users payment'">
                                        Купленный модуль:
                                    </strong>
                                    <strong  v-if="item.event === 'Data changed by admin'">
                                        Список курсов после изменения:
                                    </strong>
                                    <strong  v-if="item.event === 'Data cleared by admin'">
                                        Список курсов до очистки:
                                    </strong>

                                    {{item.payedPackage}}
                                </p>
                                <p v-if="item.coursePaymentType">
                                    <strong>Купленный курс:</strong>
                                    {{item.coursePaymentType}}
                                </p>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
        </div>

        <div class="admin-refund__result" v-if="result">
            Данные для {{mail}} успешно очищены.
        </div>
        <div class="admin-refund__result" v-if="resultEdit">
            Данные для {{mail}} успешно изменены.
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import PurchaseList from './PurchaseList.vue';

export default {
  name: 'AdminRefund',
  components: {
    PurchaseList,
  },
  data() {
    return {
      mail: '',
      userData: {},
      hasUserData: false,
      result: false,
      resultEdit: false,
      emailHints: [],
      isDataSettedFromHint: false,
      isInputFocused: false,
      debounceTimer: '',
    };
  },
  computed: {
    adminToken() {
      return this.$store.state.user.adminToken;
    },
  },
  watch: {
    mail() {
      if (this.mail.length > 3 && !this.isDataSettedFromHint) {
        clearTimeout(this.debounceTimer);

        this.debounceTimer = setTimeout(() => {
          this.getEmailHints();
        }, 300);
      } else {
        this.emailHints = [];
      }

      this.isDataSettedFromHint = false;
    },
  },
  methods: {
    asAdmin(eventType) {
      let result = false;
      if (eventType === 'Data changed by admin' || eventType === 'Data cleared by admin') {
        result = true;
      }

      return result;
    },
    getUserData() {
      const currentUrl = this.$store.getters.getLocalUrl;

      axios
        .post(`${currentUrl}/api/adminGetDataByEmail.php`, {
          adminToken: this.adminToken,
          mail: this.mail,
        })
        .then((response) => {
          if (response.data.success) {
            this.userData = response.data.result;
            this.hasUserData = true;
            this.result = false;
            this.resultEdit = false;

            this.$store.commit('setUsersPayedItems', response.data.result.purchases);
            this.$store.commit('setUserdPayedGroups', response.data.result.packages);
            this.$store.commit('setCurrentWorkingMail', this.mail);
          }
        });
    },
    hideHints() {
      setTimeout(() => {
        this.isInputFocused = false;
      }, 100);
    },
    getEmailHints() {
      const currentUrl = this.$store.getters.getLocalUrl;

      axios
        .post(`${currentUrl}/api/adminGetEmailHints.php`, {
          adminToken: this.adminToken,
          mailPart: this.mail,
        })
        .then((response) => {
          this.emailHints = [];
          if (response.data.success) {
            this.emailHints = response.data.result.emailsList;
          }
        });
    },
    setValueFromHint(emailFromHint) {
      this.isDataSettedFromHint = true;
      this.mail = emailFromHint;
      this.emailHints = [];
    },
    getReadablePaymentLog(log) {
      if (log === '') {
        return [];
      }

      const arrayLog = log.split(' ,');
      const paymentLog = [];

      if (arrayLog.length > 0) {
        arrayLog.forEach((item) => {
          paymentLog.push(JSON.parse(item));
        });
      }

      return paymentLog;
    },
  },
};
</script>

<style scoped lang="scss">
 @import "../../assets/scss/common/variables";
 @import "../../assets/scss/common/forms";

.admin-refund {
    margin: 40px 0;
    padding: 0 20px;
    min-height: calc(100vh - 300px);

    &__title {
        margin-bottom: 15px;
        font-weight: 600;
    }

    &__info {
        margin-top: 50px;
    }

    &__result {
        text-align: center;
        line-height: 50px;
        margin-top: 30px;
        color: $main-green;
    }

    &__payment-item {
        margin-bottom: 40px;
        padding: 20px;
        margin: 0 -8px;
        line-height: 24px;

        &:first-child {
            margin-top: -8px;
        }

        &:last-child {
            margin-bottom: -8px;
        }

        &:nth-child(odd) {
            background: rgba(0,0,0,0.1);
        }

        strong {
            font-weight: 900;
        }

        &.adminAdited {
            border-left: 5px $main-green solid;
        }
    }

    &__user-data {
        table {
            border: solid 1px #000;
            width: 100%;

            th, td {
                text-align: left;
                border: solid 1px #000;
                padding: 8px;
                word-break: break-all;
            }
        }
    }
}
</style>
