<template>
  <div class="landing-page">
    <BaseWebinarSuccess></BaseWebinarSuccess>
  </div>
</template>

<script>
// @ is an alias to /src
import BaseWebinarSuccess from '@/components/secondary-pages/BaseWebinarSuccess.vue';

export default {
  name: 'Home',
  components: {
    BaseWebinarSuccess,
  },
};
</script>
