<template>
    <div class="wlm-works">
        <div class="landing-title-group">
            <div id="howItWorksSectionScrollMarker"
                 class="landing-title-group__hidden-marker"
            ></div>
            <div class="landing-title-group__small">
                ПОТОК ИСЦЕЛЕНИЯ
            </div>
            <div class="landing-title-group__mid">Отзывы после первого потока исцеления</div>
        </div>
        <div class="wlm-works__video-slider glide-video">
            <div data-glide-el="track" class="glide__track">
                <div class="glide__slides">
                    <div class="wlm-works__video glide__slide">
                        <iframe
                                width="100%"
                                height="400"
                                src="https://www.youtube.com/embed/7m2G0sudU3w?modestbranding=1&rel=0"
                                frameborder="0"
                                modestbranding='1'
                                allowfullscreen
                        ></iframe>
                    </div>

                    <div class="wlm-works__video glide__slide">
                        <img src="/images/webinar_review/4.JPG">
                    </div>

                    <div class="wlm-works__video glide__slide">
                        <img src="/images/webinar_review/5.JPG">
                    </div>

                    <div class="wlm-works__video glide__slide">
                        <img src="/images/webinar_review/6.JPG">
                    </div>

                    <div class="wlm-works__video glide__slide">
                        <iframe
                                width="100%"
                                height="400"
                                src="https://www.youtube.com/embed/y2LzEiwCJ8Y?modestbranding=1&rel=0"
                                frameborder="0"
                                modestbranding='1'
                                allowfullscreen
                        ></iframe>
                    </div>
                    <div class="wlm-works__video glide__slide">
                        <iframe
                                width="100%"
                                height="400"
                                src="https://www.youtube.com/embed/m13299Y9q9A?modestbranding=1&rel=0"
                                frameborder="0"
                                modestbranding='1'
                                allowfullscreen
                        ></iframe>
                    </div>
                    <div class="wlm-works__video glide__slide">
                        <iframe
                                width="100%"
                                height="400"
                                src="https://www.youtube.com/embed/qe3mQE8zPL0?modestbranding=1&rel=0"
                                frameborder="0"
                                modestbranding='1'
                                allowfullscreen
                        ></iframe>
                    </div>
                    <div class="wlm-works__video glide__slide">
                        <iframe
                                width="100%"
                                height="400"
                                src="https://www.youtube.com/embed/AMpXkneDaLg?modestbranding=1&rel=0"
                                frameborder="0"
                                modestbranding='1'
                                allowfullscreen
                        ></iframe>
                    </div>
                    <div class="wlm-works__video glide__slide">
                        <iframe
                                width="100%"
                                height="400"
                                src="https://www.youtube.com/embed/p5Yx4-ll8DU?modestbranding=1&rel=0"
                                frameborder="0"
                                modestbranding='1'
                                allowfullscreen
                        ></iframe>
                    </div>
                </div>
            </div>
            <div class="glide__arrows" data-glide-el="controls">
                <button class="glide__arrow glide__arrow--left" data-glide-dir="<"></button>

                <div class="wlm-works__dots glide__bullets" data-glide-el="controls[nav]">
                    <button class="glide__bullet" data-glide-dir="=0"></button>
                    <button class="glide__bullet" data-glide-dir="=1"></button>
                    <button class="glide__bullet" data-glide-dir="=2"></button>
                    <button class="glide__bullet" data-glide-dir="=3"></button>
                    <button class="glide__bullet" data-glide-dir="=4"></button>
                    <button class="glide__bullet" data-glide-dir="=5"></button>
                    <button class="glide__bullet" data-glide-dir="=6"></button>
                    <button class="glide__bullet" data-glide-dir="=7"></button>
                    <button class="glide__bullet" data-glide-dir="=8"></button>
                </div>

                <button class="glide__arrow glide__arrow--right" data-glide-dir=">"></button>
            </div>
        </div>
    </div>
</template>

<script>
import Glide, { Controls, Swipe, Breakpoints } from '@glidejs/glide/dist/glide.modular.esm';

export default {
  name: 'WelcomeLandingHowItWorks',
  data() {
    return {
      complete: false,
    };
  },
  computed: {
  },
  methods: {
  },
  mounted() {
    setTimeout(() => {
      this.glide = new Glide('.glide-video', {
        perView: 2,
        gap: 20,
        peek: { before: 0, after: 150 },
        animationDuration: 300,
        rewind: false,
        breakpoints: {
          720: {
            perView: 1,
          },
        },
      }).mount({ Controls, Swipe, Breakpoints });
    }, 300);
  },
};
</script>

<style scoped lang="scss">
 @import "../../../assets/scss/common/variables";
 @import "../../../assets/scss/common/landing";
 @import "../../../assets/scss/common/glide";

.wlm-works {
    padding: 2px 24px 50px 24px;
    background: $light-blue;
    margin: -25px 0 -15px 0;

    img {
        max-height: 400px;
    }

    @media only screen and (max-width: 767px) {
        padding: 10px 24px 40px 24px;
    }

    &__video-slider {
        @media only screen and (max-width: 767px) {
            margin: 0 -24px 0 0;
        }
    }

    .glide__arrows {
        justify-content: center;
        align-items: center;
        margin-top: 40px;
        margin-left: -24px;
        display: flex;

        .glide__arrow {
            background: url('/images/right-arrow.svg') no-repeat 50% 50% $main-blue;
            width: 24px;
            height: 24px;
            border-radius: 50%;
            margin: 0 10px;
            border: 0;
            position: relative;
            top: 1px;
            flex-shrink: 0;
            cursor: pointer;

            &--right {
                transform: rotate(180deg);
            }
        }
    }

    &__dots {
        text-align: center;
        margin: 0 10px;

        .glide__bullet {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            margin: 0;
            background: rgba($main-blue, 0.1);
            border: 5px solid $light-blue;;
            padding: 0;
            box-sizing: unset;
            cursor: pointer;
        }

        .glide__bullet--active {
            background: $main-blue;
        }
    }

    .wlm-works__video {
        img {
            max-height: 400px;
        }
    }

    &__video {
        width: 220px;
        margin: 0 10px;
        position: relative;
        text-align: center;
        background: rgba(0,0,0,0.02);

        &:before {
            display: block;
            content: "";
            width: 100%;
            height: 60px;
            position: absolute;
            left: 0;
            top: 0;
        }

        @media only screen and (max-width: 767px) {
            width: auto;
            margin: auto;
            transform: scale(0.85);
            transition: transform 200ms;
            background: transparent;

            &.glide__slide--active {
                transform: scale(1);
            }
        }
    }

    &__descr {
        font-size: 16px;
        line-height: 24px;
        margin-top: 40px;

        @media only screen and (max-width: 767px) {
            font-size: 14px;
            margin-top: 30px;
            line-height: 21px;
        }
    }

    &__video-title {
        font-size: 18px;
        line-height: 27px;
        font-weight: 500;
        text-align: center;
        margin: 30px 0;
    }
}
</style>
