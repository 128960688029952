<template>
  <div class="landing-page">
    <BaseWebinarPaymentSuccess></BaseWebinarPaymentSuccess>
  </div>
</template>

<script>
// @ is an alias to /src
import BaseWebinarPaymentSuccess from '@/components/secondary-pages/BaseWebinarPaymentSuccess.vue';

export default {
  name: 'WebinarPaymentSuccess',
  components: {
    BaseWebinarPaymentSuccess,
  },
};
</script>
